import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useLocation } from 'react-router-dom';

export enum Steps {
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const { getAudioAndVideoTracks } = useVideoContext();
  const [step, setStep] = useState(Steps.deviceSelectionStep);

  const useQuery = () => new URLSearchParams(useLocation().search);
  
  const userName = useQuery().get("userName") ?? "";
  const roomId =useQuery().get("roomId") ?? "";
  const token = useQuery().get("token") ?? "";

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
        <DeviceSelectionScreen token={token} name={userName} roomName={roomId} setStep={setStep} />
    </IntroContainer>
  );
}
