import React from 'react';
import Video from 'twilio-video';
import { Container, Link, Typography, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    marginTop: '2.5em',
  },
  paper: {
    padding: '1em',
  },
  heading: {
    marginBottom: '0.4em',
  },
});

export default function UnsupportedBrowserWarning({ children }: { children: React.ReactElement }) {
  const classes = useStyles();

  if (!Video.isSupported) {
    return (
      <Container>
        <style>
          {`.button {
              background-color: #4CAF50;
              border: none;
              color: white;
              padding: 10px 30px;
              text-align: center;
              text-decoration: none;
              display: inline-block;
              cursor: pointer;
              font-weight: 600;
              font-size: 16px;
            }
            .button:hover {
              background-color: #2c9630
            }
            /* Adding transformation when the button is active */
            .button:active {
                transform: scale(0.95);
                /* Scaling button to 0.98 to its original size */
            }
            `}
        </style>
        <Grid container justifyContent="center" className={classes.container}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Typography variant="h4" className={classes.heading}>
                Este navegador web não é permitido
              </Typography>
              <Typography>
                Por favor, utilize outro navegador
                <br />
                Navegadores permitidos: Chrome e Safari
                <br />
                Copie o link abaixo e cole em um dos navegadores permitidos
                <br />
                <br />
                <button className="button" onClick={() => navigator.clipboard.writeText(window.location.href)}>
                  Copiar link
                </button>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return children;
}
